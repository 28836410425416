import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AccountCreated } from './AccountCreated/AccountCreated';
import { client } from '../../services/axiosClient';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { UserExists } from '../UserExists/UserExists';

// import { useNavigate } from 'react-router-dom';

export const Registration = () => {

  interface FormData {
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    passwordCheck: string
    agreement: boolean
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>();
  const [ accountCreated, setAccountCreated ] = useState<boolean>(false);
  const [ error, setError ] = useState<boolean>(false);

  const BASE_URL = process.env.REACT_APP_STATIC_URL;

  const onSubmit: SubmitHandler<FormData> = async (data) => {
  
    try {
      await client.post('/register', data);
  
      setAccountCreated(true);
    } catch (error) {

      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <img
            className='mx-auto h-24 w-auto'
            src={BASE_URL + '/ve_logo.png'}
            alt='VE logo'
          />
          <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
            Register a new account
          </h2>
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
          <form onSubmit={handleSubmit(onSubmit)} className='space-y-6' action='#' method='POST'>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Email address
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  type='email'
                  autoComplete='email'
                  { ...register('email', {
                    required: 'Email is required', 
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }  } )}

                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='email'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='firstName'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                First Name
              </label>
              <div className='mt-2'>
                <input
                  id='firstName'
                  // name='firstName'
                  type='text'
                  autoComplete='given-name'
                  placeholder='ex. John'
                  { ...register('firstName', { required: 'First Name is required' } )}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='firstName'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>} />
              </div>
            </div>
            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Password
                </label>
              </div>
              <div className='mt-2'>
                <input
                  id='password'
                  type='password'
                  autoComplete='current-password'
                  { ...register('password', { 
                    required: 'Password is required', 
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters'
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/,
                      message: 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
                    }
                  })}
                  
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
                />
              </div>
            </div>

            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='password-retype'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Re-type password
              
                </label>
              </div>
              <div className='mt-2'>
                <input
                  id='password-retype'
                  type='password'
                  autoComplete='current-password'
                  {...register('passwordCheck', { 
                    required: 'You must retype your password',
                    validate: value =>
                      value === watch('password') || 'The passwords do not match'
                  })} 
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
                <ErrorMessage
                  errors={errors}
                  name='passwordCheck'
                  render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>} />
              </div>
            </div>
            <div className="space-y-5">
              <div className="relative flex items-start">
                <div className=" text-sm leading-6">
                  <input 
                    type="checkbox" 
                    className="h-4 w-4 rounded border-gray-300 text-orange-600 focus:ring-orange-600"
                    {...register('agreement', { required: 'You MUST accept the agreement' })} 
                  />
                  <span> I confirm that I have read the <Link to={'https://vintageexpress.eu/terms-and-conditions-of-carriage/'}
                    target='_blank'><u>agreement</u></Link> and I accept it.</span>
                </div>
                
              </div>
              <ErrorMessage
                errors={errors}
                name='agreement'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>} />
            </div>
            <div>
              <button
                type='submit'
                className='flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
              >
                Register
              </button>
            </div>
          </form>

          <p className='mt-10 text-center text-sm text-gray-500'>
            Already have an account?{' '}
            <Link
              to='/'
              className='font-semibold leading-6 text-orange-600 hover:text-orange-500'
            >
              Sign in!
            </Link>
          </p>

         
        </div>
        <div className='mt-5'>
          {accountCreated && <AccountCreated />}
        </div>
        {error && <UserExists />
        }
       
      </div>
    </>
  );
};


/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/


