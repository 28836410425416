import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { PasswordSet } from './PasswordSet/PasswordSet';
import { useParams } from 'react-router-dom';
import { client } from '../../services/axiosClient';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

export const NewPassword = () => {
  type FormData = {
    password: string,
    passwordCheck: string
  }
  const { register, handleSubmit, formState: { errors }, watch } = useForm<FormData>();
  const [ newPass, setNewPass ] = useState<boolean>(false);


  const BASE_URL = process.env.REACT_APP_STATIC_URL;

  const { passToken } = useParams();


  const onSubmit: SubmitHandler<FormData> = async (data) => {
  
  
    const dataToSend = {
      passToken,
      password: data.password
    };

    try {
      await client.post('/setnewpass', dataToSend);
  
      setNewPass(true);
    } catch (error) {
      console.error('Error: Server error');
    
    }
  };


  return (
    <>
      <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
          <img
            className='mx-auto h-24 w-auto'
            src={BASE_URL + '/ve_logo.png'}
            alt='VE logo'
          />
          <h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
            Set new password
          </h2>
        </div>

        <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
          <form onSubmit={handleSubmit(onSubmit)} className='space-y-6' action='#' method='POST'>
   
            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Password
                </label>

              </div>
              <div className='mt-2'>
                <input
                  id='password'
                  type='password'
                  autoComplete='current-password'
                  {...register('password', {
                    required: 'Password is required', 
                    minLength: {
                      value: 8,
                      message: 'Password must have at least 8 characters'
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/,
                      message: 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
                    }

                  })}

                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='password'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
    
            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='password-retype'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Re-type password
              
                </label>
                {/* <div className='text-sm'>
                  <a
                    href='#'
                    className='font-semibold text-orange-600 hover:text-orange-500'
                  >
                    Forgot password?
                  </a>
                </div> */}
              </div>
              <div className='mt-2'>
                <input
                  id='password-retype'
                  type='password'
                  autoComplete='current-password'
                  {...register('passwordCheck', {
                    required: 'Re-typed Email is required',
                    validate: value => 
                      value === watch('password') || 'Passwords do not match',
                  })}
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6'
                />
              </div>
              <ErrorMessage
                errors={errors}
                name='passwordCheck'
                render={({ message }) => <p className='text-red-700 font-medium text-xs inline-flex items-center'>{message}</p>}
              />
            </div>
            <div>
              <button
                type='submit'
                className='flex w-full justify-center rounded-md bg-orange-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600'
              >
                Set password
              </button>
            </div>
          </form>

 

         
        </div>
        <div className='mt-5'>
          {newPass && <PasswordSet />}
        </div>
       
      </div>
    </>
  );
};
